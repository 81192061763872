import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_usuario } from "../../models";
import styled from "styled-components";
import { TreeGroup } from "./GruposUsuario";
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { COLOR_PRIMARY } from "../../theme/styles";
import { AplicativosUsuario } from "./AplicativosUsuario";
import { useRef } from "react";
//import { useAuth } from "../../contexts/AuthProvider/useAuth";
//import { Privilegio } from "../../contexts/AuthProvider/types";

const ContainerDetail = styled.div`
    padding: 1rem;
`

export const Usuario = () => {
    //const auth = useAuth();
    const refTable = useRef<any>();
    const refModalApp = useRef<any>();

    const columns = [
        { field: 'usucodigo', header: 'Código' },
        { field: 'usunome', header: 'Nome' },
        { field: 'usuemail', header: 'E-mail' },
        { field: 'aplicativos', header: 'Aplicativos Liberados' },
        //{ field: 'usuativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '4rem', width: '4rem' } }
    ];

    const details = (row: any) => {
        return (
            <ContainerDetail>
                <TreeGroup usucodigo={row.usucodigo} />
            </ContainerDetail>
        )
    }

    function functions(row: any) {
        let _result = [];

        //  if (auth.privilegio === Privilegio.Admnistrador)
        _result.push({ label: 'Configurar acesso aos aplicativos', click: () => refModalApp.current.openModal(row), icon: <PhonelinkSetupIcon />, color: COLOR_PRIMARY })

        return _result;
    }

    return (
        <>
            <ConsultaPadrao ref={refTable} model={model_usuario} columns={columns} sortField="usunome" details={details} functions={functions} disableedit />
            <AplicativosUsuario ref={refModalApp} onRefresh={() => refTable.current.refresh()} />
        </>
    )
}