import { Button, ButtonProps } from 'react-bootstrap';
import { COLOR_BACKGROUND_DARK, COLOR_PRIMARY } from '../../theme/styles';
import styled from 'styled-components';
import { lighten } from 'polished';

const Icon = styled.div`
    display: flex;
    margin-right: 5px;
    pointer-events: none;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    color: ${COLOR_PRIMARY}
`

interface ExButtonProps extends ButtonProps {
    icon?: any;
    iconcenter?: any;
}

export const ExButton = (props: ExButtonProps) => {
    return (
        <Button
            size={props.size ? props.size : 'sm'}
            {...props}
            style={{
                backgroundColor: COLOR_BACKGROUND_DARK,
                borderColor: COLOR_BACKGROUND_DARK,
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                color: COLOR_PRIMARY,
                padding: '8px',
                paddingLeft: '24px',
                paddingRight: '24px',
                fontSize: '16px',
                ...props.style,
            }}
            onFocus={(e: any) => {
                e.target.style.boxShadow = `0 0 0 0.05rem ${COLOR_BACKGROUND_DARK}80, 0 0 0 0.1rem ${COLOR_BACKGROUND_DARK}40`;
            }}
            onBlur={(e: any) => {
                e.target.style.boxShadow = 'none';
            }}
            onMouseDown={(e: any) => {
                e.target.style.backgroundColor = lighten(0.1, COLOR_BACKGROUND_DARK);
                e.target.style.borderColor = lighten(0.1, COLOR_BACKGROUND_DARK);
            }}
            onMouseUp={(e: any) => {
                e.target.style.backgroundColor = COLOR_BACKGROUND_DARK;
                e.target.style.borderColor = COLOR_BACKGROUND_DARK;
            }}
        >
            {props.icon &&
                <Icon>
                    {props.icon}
                </Icon>
            }
            {props.iconcenter &&
                <Icon style={{ margin: 0 }}> {props.iconcenter} </Icon>
            }
            {props.children}
        </Button>
    )
}

interface RoundedButtonProps extends ExButtonProps { }

export const RoundedButton = (props: RoundedButtonProps) => {
    return <ExButton {...props} style={{ ...props.style, display: '', width: '40px', height: '40px', paddingLeft: 0, paddingRight: 0, borderRadius: '20%' }} />;
};