import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

export function navigate(to: any) {
    window.history.pushState({}, '', to);
    window.dispatchEvent(new PopStateEvent('popstate'));
}

export function isMobileDevice() {
    return window.matchMedia('(max-width: 767px)').matches;
};

export function onValidate(elements: any) {
    let validated = true;

    console.log('onValidate', elements)

    if (elements)
        Object.values(elements.current).forEach((input: any) => {
            if ((validated) && (!input.validate())) {
                validated = false
            }
        });

    console.log(validated)
    return validated;
}

export function cpfIsValid(cpf: any) {
    var soma = 0;
    var resto;

    var cpfStr = cpf.replace(/[^\d]/g, '')

    if (cpfStr.length !== 11)
        return false;

    if ([
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
    ].indexOf(cpfStr) !== -1)
        return false;

    for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpfStr.substring(i - 1, i)) * (11 - i);

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11))
        resto = 0

    if (resto !== parseInt(cpfStr.substring(9, 10)))
        return false

    soma = 0

    for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpfStr.substring(i - 1, i)) * (12 - i)

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11))
        resto = 0

    if (resto !== parseInt(cpfStr.substring(10, 11)))
        return false

    return true
}

export function emailIsValid(email: any) {
    var user = email.substring(0, email.indexOf("@"));
    var dominio = email.substring(email.indexOf("@") + 1, email.length);

    if ((user.length >= 1) &&
        (dominio.length >= 3) &&
        (user.search("@") === -1) &&
        (dominio.search("@") === -1) &&
        (user.search(" ") === -1) &&
        (dominio.search(" ") === -1) &&
        (dominio.search(".") !== -1) &&
        (dominio.indexOf(".") >= 1) &&
        (dominio.lastIndexOf(".") < dominio.length - 1))
        return true
    else
        return false
}

export function copyText(value: any) {
    copy(value);
    toast.success('Copiado com sucesso.')
}