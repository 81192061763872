import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const CardTable = styled(Card)`
    border-radius: 4px;
    background-color: white;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 4px;
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-right: 10px;
    height: 100%;
    margin-bottom: 3px;
`

export const Title = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-left: 1rem;
    height: 100%;
`

