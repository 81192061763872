import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Dropdown, Row } from 'react-bootstrap';
import Logo from '../../images/logoPequena.png'
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { AppRoutes } from '../../routes';
import { DropdownItem, DropdownToggle, ExNavDropdown, ExNavDropdownHeader, ExNavDropdownItem, ExNavItem, TitleToogle } from '../../components/ExNavbar';
import { FaBuildingWheat } from "react-icons/fa6";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import jsPDF from 'jspdf';


export const Principal = () => {
    const auth = useAuth();

    function alterUser(grupo: string) {
        if (auth.usuemail && (auth.ususenha || auth.usutoken)) {
            auth.authenticate(auth.usuemail, auth.ususenha, auth.usutoken, grupo).then(() => {
                window.location.reload();
            })
        }
    }

    const pdf = () => {
        const doc = new jsPDF({ format: [792 * 1.1, 612 * 1.1], unit: 'pt', putOnlyUsedFonts: true });
        doc.html(`<table style="height: 95%; margin-top: 5%; ">
   <thead>
      <tr>
         <th>
            <div>
               <div style="display: flex; flex-direction: row;">
                  <div style="display: flex; flex-direction: column; width: 220px; justify-content: center">
                     <img src="https://lucianamaraestudio.com.br/wp-content/uploads/2022/01/logotipo-personalizado-mesa.png" style="max-height: 50px; max-width: 130px; object-fit: cover;" aling="left" />
                  </div>
                  <div style="flex: 1; justify-content: center; align-itens: center">
                     <span class="title" style="margin-top: 0">Teste 1</span>
                     <span class="subtitle"></span>
                  </div>
                  <div style="display: flex; flex-direction: row; width: 220px; justify-content: flex-end">
                    <div style="display: flex; flex-direction: column;  justify-content: center">  
                     <span class="subtitle">Romaneio Nº</span>
                     <span class="subtitle" style="color: red; margin-top: 0">175</span>               

                    </div>
                  </div>
               </div>
            </div>
         </th>
      </tr>
          <tr style="background-color: transparent">
                <td>
               <div>
                  <hr/>
                  <div style="display: flex; flex-direction: row; justify-content: space-between"> 
                     <div style="flex: 1">
                        <p class="itemline subtitle">Cliente: Jose </p>
                        <p class="itemline subtitle">CPF/CNPJ: 52.539.989/0001-00</p>
                        <p class="itemline subtitle">IE: isento </p>
                     </div>
                     <div style="flex: 1">
                        <p class="itemline subtitle" style="text-align: left"></p>
                     </div>
                     <div>
<span class="itemline subtitle">&nbsp<style='text-align:right'>Emissão: 11/12/2024</span>
                     </div>
                  </div>
                  <div style="display: flex; flex-direction: row; margin-top: 0.3em">
<p class="itemline subtitle">Transportador: Transportadora Alves E Oliveira Ltda </p>
<p class="itemline subtitle">Tel: (34) 99132-3200</p>
<div style="flex: 1"></div>
<p class="itemline subtitle">Veículo: </p>
<div style="flex: 1"></div>
<p class="itemline subtitle">Frete: Por conta do remetente</p>
<div style="flex: 1"></div>
<p class="itemline subtitle">Seguro: Não</p>
<p class="itemline subtitle"></p>
                  </div>
               </div>
                </td>
          </tr>
   </thead>
   <tbody>
      <tr style="page-break-inside: auto">
         <td>
            <table>
               <thead>
                  <tr style="background-color: #e4e6e8;">
                  <th style="text-align: center">Produto</th>
                  <th style="text-align: center">Calibre</th>
                  <th style="text-align: center">PL</th>
                  <th style="text-align: center">Qtd.</th>
                  <th style="text-align: center">Embalagem</th>
                  <th class="floatval" style="text-align: center">Unit.</th>
                  <th class="floatval" style="text-align: center">Total</th>
                  </tr>
               </thead>
               <tbody>

                  <tr>
                     <td>Alho Comercial</td>
                     <td style="text-align: center">4</td>
                     <td style="text-align: center">T2</td>
                     <td style="text-align: center">11</td>
                     <td style="text-align: center">Caixa 10kg</td>
                     <td class="floatval">R$ 12,00</td>
                     <td class="floatval" style="flex: 1; text-align: right">R$ 132,00</td>        
                  </tr>
                  <!-- -->
                  <tr>
                     <td>Alho Comercial CAT II</td>
                     <td style="text-align: center">6</td>
                     <td style="text-align: center">T2</td>
                     <td style="text-align: center">12</td>
                     <td style="text-align: center">Caixa 10kg</td>
                     <td class="floatval">R$ 56,00</td>
                     <td class="floatval" style="flex: 1; text-align: right">R$ 672,00</td>        
                  </tr>
                  <!-- -->
                  <tr>
                     <td>Alho Comercial 2</td>
                     <td style="text-align: center">5</td>
                     <td style="text-align: center">T2</td>
                     <td style="text-align: center">1.2</td>
                     <td style="text-align: center">Caixa 10kg</td>
                     <td class="floatval">R$ 22,00</td>
                     <td class="floatval" style="flex: 1; text-align: right">R$ 26,40</td>
                  </tr>
                  <!-- -->
                  <tr>
                     <td>Alho Comercial 2</td>
                     <td style="text-align: center">10</td>
                     <td style="text-align: center">T2</td>
                     <td style="text-align: center">1.8</td>
                     <td style="text-align: center">Caixa 10kg</td>
                     <td class="floatval">R$ 223,00</td>
                     <td class="floatval" style="flex: 1; text-align: right">R$ 401,40</td>        
                  </tr>

               </tbody>
               <tfoot style="background-color: transparent">
                  <tr style="background-color: transparent">
                     <th>4 itens</th>
                     <th></th>
                     <th colspan="3" style="text-align: right">Volumes:</th>
                     <th colspan="2" class="floatval" style="border-top: 1px solid">26</th>        
                  </tr>
                  <tr style="background-color: transparent">
                     <th></th>
                     <th></th>
                     <th colspan="3" style="text-align: right">Valor Total:</th>
                     <th colspan="2" class="floatval">R$ 1.231,80</th>
                  </tr>

                  <tr style="background-color: transparent">
                     <th></th>
                     <th></th>
                     <th colspan="3" style="text-align: right">Total Líquido:</th>
                     <th colspan="2" class="floatval">R$ 1.231,80</th>
                  </tr>
               </tfoot>
            </table>
         </td>
      </tr>
   </tbody>
   <tfoot>
      <tr>
         <td>

            <div class="itemline" style="justify-content: space-between;">
               <div style="flex: 1; display: flex; flex-direction: column; justify-content: space-between;">
                  <span>Observação: </span>
                  <table style="border-collapse: collapse">
                     <thead>
                        <tr style="background-color: #e4e6e8;">
                        <th colspan="2"><span>Pagamento:  - </span></th>
                        </tr>
                     </thead>
                     <tbody>

                     </tbody>
                  </table>
               </div>
               <div style="flex: 1; display: flex; flex-direction: column;">
                  <div style="height: 60px"></div>
                  <span class="signfield">Assinatura</span>
               </div>
            </div>
            <hr/>
            <div class="itemline" style="justify-content: space-between">
               <p style="flex: 1; margin: 0">11/12/2024 23:01:44</p>
               <div style="display: flex; flex-direction: column;">
                  <img src="https://i.ibb.co/gjK1Zvy/black-logo-white-background.png" style="height: 12px; width: auto; display: block; object-fit: contain;" aling="center" />
               </div>
               <p style="text-align: right; flex: 1; margin: 0">Vendedor: Exalt Soluções Digitais</p>
            </div>
         </td>
      </tr>
   </tfoot>
</table>
<style>
        @media print {
                tr {
                        page-break-inside: avoid;
                        break-inside: avoid;
                        page-break-after:auto;
                        break-after:auto;
                }

                tfoot {
                        display: table-footer-group;
                }

                thead {
                        display:  table-header-group;
                }
        }

        @page {
                size: A4;
                margin-left: 0;
                margin-right: 0;
        }

    p {
        font-size: 0.83em
    }

        table {
                width: 100%;
                padding-bottom: 10px;
        }

        tbody {
                vertical-align: baseline;
        }

        th {
                text-align: left;
        }

        tr {
                margin-top: 0px;
        }

        tr:nth-child(even) {
                background-color: #f0f4f6;
        }

    td, th {
        font-size: 0.83em
    }

    p.itemline.subtitle {
        margin-bottom: 0;
        margin-top: 0.3em;
    }

        td, th {
                padding-top: 3px;
                padding-bottom: 3px;
                padding-left: 3px;
                padding-right: 3px;
        }

        body {
                font-family: calibri, arial;
                print-color-adjust: exact;
        }

        .title {
                text-align: center;
        }

        .subtitle {
                text-align: center;
                margin: 8px;
                white-space: pre;
        }

        .empdata {
                text-align: center;
                margin: 0px;
        }

        .date {
                text-align: center;
                margin: 5px;
        }

        .pesli {
                padding: 10px;
        }

        .pesquantidadevalue {
                position: absolute;
                right: 20px;
                border: 1px #000000;
        }

        .totalgroupli {
                background-color: #b8b8b8;
                padding: 10px;
        }

        .totalgrouplabel {
                text-transform: uppercase;
                color: #ffffff;
                font-weight: bold;
        }

        .totalgroupvalue {
                position: absolute;
                right: 20px;
                color: #ffffff;
                font-weight: bold;
        }

        .highlight {
                background-color: #e3e3e3;
        }

        .itemline {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
        }

        .floatval {
                text-align: right;
                white-space: nowrap;
        }

        .signfield {
                margin-top: 80px;
                margin-bottom: 5px;
                margin-left: 50px;
                margin-right: 50px;
                flex: 1;
                text-align: center;
                border-top: 1px solid;
                z-index: 1;
        }

        body{
        line-height: initial !important;
        letter-spacing: 0.2px
    }
</style>`, {
            width: 612, callback: (doc) => {
                console.log('passa')
                doc.save()
            }, 
        }).catch(w => console.log(w))
    }

    return (
        <div style={{ height: '100vh' }}>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container style={{ height: '60px' }}>
                    <Navbar.Brand href="/">
                        <img src={Logo} height={'40px'} alt='' />
                    </Navbar.Brand>
                    {(auth.grupos) &&
                        <Nav className="me-auto">
                            <ExNavItem>
                                <Dropdown>
                                    <DropdownToggle>
                                        <FaBuildingWheat style={{ position: 'absolute', left: 10, fontSize: '30px', top: 8 }} />
                                        <TitleToogle>Selecione o grupo:</TitleToogle>
                                        {auth.grupo.grudescricao}
                                    </DropdownToggle>

                                    <Dropdown.Menu>
                                        {
                                            auth.grupos.map((grupo: any, index: any) => (
                                                <DropdownItem key={index} onClick={() => alterUser(grupo)}>{grupo.grudescricao}</DropdownItem>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ExNavItem>
                        </Nav>
                    }
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" />
                        <Nav className="me-auto">
                            <ExNavItem to="/usuario">Usuários</ExNavItem>
                            <ExNavItem to="/grupo">Grupo</ExNavItem>
                            <ExNavDropdown title="Anomalias">
                                <div style={{ width: '400px' }}>
                                    <Row>
                                        <ExNavDropdownHeader icon={<VscGitPullRequestGoToChanges />} title={'Cadastros'}>
                                            <ExNavDropdownItem to="/camposrequisicao">Campos</ExNavDropdownItem>
                                        </ExNavDropdownHeader>
                                    </Row>
                                </div>
                            </ExNavDropdown>
                            <ExNavDropdown title="Indicadores">
                                <div style={{ width: '400px' }}>
                                    <Row>
                                        <ExNavDropdownHeader icon={<VscGitPullRequestGoToChanges />} title={'Cadastros'}>
                                            <ExNavDropdownItem to="/credenciaislooker">Credenciais para Looker Studio</ExNavDropdownItem>
                                        </ExNavDropdownHeader>
                                    </Row>
                                </div>
                            </ExNavDropdown>
                        </Nav>
                        <Nav className="me-auto">
                        <ExNavItem onClick={() => auth.logout()}>Sair</ExNavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
            <AppRoutes />
        </div >
    )
}