import { ToastContainer } from "react-toastify"
import SomenteLogoBranca from "../../images/SomenteLogoBranca.png";
import './styles.css';

export const Notification = () => {
    return (
        <ToastContainer
            position="top-center"
            autoClose={5000}
            limit={3}
            closeOnClick
            theme="colored"
            icon={<img src={SomenteLogoBranca} width={'28px'} alt='' style={{ marginRight: '10px' }} />}
        />
    )
}