import { Card, Form, Row } from "react-bootstrap"
import { Input } from "../../components/Input"
import { useRef, useState } from "react";
import { ExButton } from "../../components/ExButton";
import styled from "styled-components";
import LogoPequena from "../../images/logoPequena.png"
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import LoginIcon from '@mui/icons-material/Login';
import { isMobileDevice } from "../../commons/utils";
import { Api } from "../../api/adress";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import image from '../../images/background.svg'

const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-width: 100%;
    background-image: url(${image});
    background-size: cover;
`
const LoginWrapper = styled.div`
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-width: ${isMobileDevice() ? '100%' : '450px'};
    max-height: ${isMobileDevice() ? '100%' : '600px'};
    box-shadow: rgba(93, 93, 93, 0.3) 0px 0px 15px 2px;
    background-color: white
`
const Logo = styled.img`
    width: 100%;
    padding: 0 3.5rem;
`

export const Login = () => {
    const auth = useAuth();

    const usernameRef = useRef<any>();
    const passwordRef = useRef<any>();
    const validationCodeRef = useRef<any>();

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState<any>();
    const [showValidationCode, setShowValidationCode] = useState(false)

    async function getGrupos(token: string, usutoken?: string) {
        const config = { headers: { 'Authorization': `Bearer ${token}` } }

        await Api.get('/v1/grupo?grutipo=0', config)
            .then((response: any) => {
                auth.setGruSuperiores(response.data);
                authenticate(email || usernameRef.current?.getValue(), passwordRef.current?.getValue(), usutoken, response.data[0]);
            })
            .catch((error: any) => {
                console.log(error)
                var msg = error.response.data.message ? error.response.data.message : 'Não foi possível encontrar os grupos do usuário.';
                toast.error(msg, { toastId: msg });
            })
    }

    async function authenticate(usuemail: string, ususenha?: string, usutoken?: string, grupo?: any) {
        console.log('a', usuemail)
        await auth.authenticate(usuemail, ususenha, usutoken, grupo)
            .then((token: any) => {
                usernameRef.current?.setError(false);
                passwordRef.current?.setError(false);

                if (!grupo)
                    getGrupos(token, usutoken)
                else
                    toast.success('Login realizado com sucesso.');
            })
            .catch((error: any) => {
                setValidated(false);
                usernameRef.current?.setError(true);
                passwordRef.current?.setError(true);

                console.log(error)
                var msg = error.response?.data?.message ? error.response?.data?.message : 'Não foi possível realizar a autenticação no momento.';

                toast.error(msg, { toastId: msg });
            });
    }

    const handleSubmit = (e: any) => {
        if (showValidationCode) {
            auth.validateCode(email, validationCodeRef.current.getValue()).then((token: any) => {
                authenticate(email, undefined, token)
            }).catch(error => {
                var msg = error.response?.data?.message ? error.response?.data?.message : 'Não foi possível realizar a autenticação no momento.';
                toast.error(msg, { toastId: msg });
            })
        } else {
            const form = e.currentTarget;

            if (form.checkValidity() === true)
                authenticate(usernameRef.current.getValue(), passwordRef.current.getValue())
            else
                setValidated(true);
        }

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <LoginContainer>
            <LoginWrapper>
                <Form noValidate validated={validated} className="w-100" onSubmit={handleSubmit}>
                    <Row className="mb-5 pt-4 justify-content-center">
                        <Logo src={LogoPequena} />
                    </Row>
                    <Row className="justify-content-center text-center">
                        <Card className="border-0 p-0">
                            <Card.Body>
                                <Card.Title>BEM-VINDO</Card.Title>
                                <Card.Text className="mb-4">Preencha os dados de login para acessar</Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    {showValidationCode ?
                        <>
                            <Row className="mb-2 justify-content-center">
                                <Input ref={validationCodeRef} name="validationcode" id="validationcode" type="text" label="Código de Validação" required />
                            </Row>
                            <Row className="mb-2 justify-content-end m-0">
                                <ExButton type="submit" icon={<LoginIcon />} style={{ justifyContent: 'center' }} >Validar</ExButton>
                            </Row>
                        </>
                        :
                        <>
                            <Row className="mb-2 justify-content-center">
                                <Input ref={usernameRef} name="email" id="email" type="text" label="E-mail" required />
                            </Row>
                            <Row className="mb-4 justify-content-center">
                                <Input ref={passwordRef} name="senha" id="senha" type="password" label="Senha" required />
                            </Row>
                            <Row className="mb-2 justify-content-end m-0">
                                <ExButton type="submit" icon={<LoginIcon />} style={{ justifyContent: 'center' }} >Acessar</ExButton>
                            </Row>
                        </>
                    }
                    <Row className="justify-content-center">
                        <GoogleLogin
                            theme="outline"
                            size="large"
                            onSuccess={user => {
                                if (user.credential) {
                                    let userDecoded: any = jwtDecode(user.credential)
                                    console.log(userDecoded)
                                    auth.signin(userDecoded.email, userDecoded.name).then(() => {
                                        setEmail(userDecoded.email)
                                        setShowValidationCode(true)
                                    })
                                }
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            width={385}
                        />
                    </Row>
                </Form>
            </LoginWrapper>
        </LoginContainer>
    )
}