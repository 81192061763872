import { Card } from "react-bootstrap"
import { CardTable, Header, Icon, Title } from "../../components/ConsultaPadrao/components"
import { isMobileDevice } from "../../commons/utils"
import { model_grupo } from "../../models"
import { useEffect, useRef, useState } from "react"
import { useAuth } from "../../contexts/AuthProvider/useAuth"
import styled from "styled-components";
import { ExButton } from "../../components/ExButton"
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { ExCadastroPadrao } from "../../components/ExCadastroPadrao"
import { FaPlus } from "react-icons/fa6";

const Container = styled.div`
    background-color: white;
`
const RowMain = styled.div`
    padding: 10px;
`
const RowWrapper = styled.div`
    display: flex;
    align-items: center;
`
const RowChild = styled.div`
    margin-left: '20px';
`
export const Grupo = () => {
    const auth = useAuth();
    const refCadastroPadrao = useRef<any>();

    const [data, setData] = useState<any[]>();

    async function refresh() {
        auth.get(model_grupo.api_get).then((response: any) => {
            if (process.env.NODE_ENV === 'development')
                console.log('Consulta ' + model_grupo.name, response.data);

            setData(response.data)
        }).catch((error: any) => {
            console.log(error);
        }).finally(() => {
        });
    }

    useEffect(() => {
        refresh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleExpandir = (gruid: number) => {
        setData(data?.map(grupo => {
            if (grupo.gruid === gruid) {
                return { ...grupo, expandido: !grupo.expandido };
            }
            return grupo;
        }));
    };

    const renderizarGrupo = (grupo: any) => {
        const childs = data?.filter(g => g.gruidpai === grupo.gruid);
        const hasChilds = childs && childs.length > 0;

        return (
            <RowMain key={grupo.gruid}>
                <RowWrapper>
                    {hasChilds && (
                        <ExButton
                            onClick={() => toggleExpandir(grupo.gruid)}
                            style={{ display: 'flex', justifyContent: 'center', marginRight: '38px', borderRadius: '50%', width: '30px', height: '30px', paddingLeft: 0, paddingRight: 0 }}
                            iconcenter={grupo.expandido ? <IoIosArrowUp /> : <IoIosArrowForward />}
                        />
                    )}
                    <span style={{ marginLeft: hasChilds ? 0 : '38px' }}>{grupo.grudescricao}</span>
                </RowWrapper>
                {grupo.expandido && hasChilds && (
                    <RowChild>
                        {childs.map(child => renderizarGrupo(child))}
                    </RowChild>
                )}
            </RowMain>
        );
    };

    return (
        <>
            <CardTable className={isMobileDevice() ? "m-3 mb-4 mt-3" : "m-4 mb-4"}>
                <Card.Body className={isMobileDevice() ? "p-1" : "p-2"}>
                    <Header>
                        <Title>
                            <Icon>{model_grupo.icon}</Icon>
                            {model_grupo.name}
                        </Title>
                        {model_grupo.base &&
                            <ExButton icon={<FaPlus />} onClick={() => refCadastroPadrao.current.openModal()}>Novo</ExButton>
                        }
                    </Header>
                    <Container>
                        {data?.filter(grupo => grupo.gruidpai === null).map(grupo => renderizarGrupo(grupo))}
                    </Container>
                </Card.Body>
            </CardTable>
            {model_grupo.base &&
                <ExCadastroPadrao ref={refCadastroPadrao} model={model_grupo} success={() => refresh()} />
            }
        </>
    )
}