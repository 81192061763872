import { Card } from "react-bootstrap"
import { CardTable, Header, Icon, Title } from "../../components/ConsultaPadrao/components"
import { isMobileDevice } from "../../commons/utils"
import { model_grupo } from "../../models"
import { useEffect } from "react"
import { useAuth } from "../../contexts/AuthProvider/useAuth"
import styled from "styled-components";

const Container = styled.div`
    background-color: white;
`
export const CredenciaisLooker = () => {
    const auth = useAuth();

    async function refresh() {
        auth.get(model_grupo.api_get).then((response: any) => {

        }).catch((error: any) => {
            console.log(error);
        }).finally(() => {
        });
    }

    useEffect(() => {
        refresh();
    }, [])

    return (
        <>
            <CardTable className={isMobileDevice() ? "m-3 mb-4 mt-3" : "m-4 mb-4"}>
                <Card.Body className={isMobileDevice() ? "p-1" : "p-2"}>
                    <Header>
                        <Title>
                            <Icon>{model_grupo.icon}</Icon>
                            Credenciais para Looker Studio
                        </Title>
                    </Header>
                    <Container>
                        <h2>Você precisará informar seu e-mail e o token para realizar a conexão:</h2>
                        <h2>E-mail: </h2>
                        <h2>Token: </h2>
                        <a href="https://lookerstudio.google.com/datasources/create?connectorId=AKfycbw1E5qV0KBB8bw0gLPbZ0fhpyJyok5lv1UmzHylzhs" target="_blank"><h2>Gerar nova fonte de dados</h2></a>
                    </Container>
                </Card.Body>
            </CardTable>
        </>
    )
}