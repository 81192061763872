import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_aplicativo_usuario } from "../../../models";
import { ExSwitch } from "../../../components/ExSwitch";
import { RoundedButton } from "../../../components/ExButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import { copyText } from "../../../commons/utils";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";

const ButtonCopy = (props: any) => {
    return (
        props.url &&
        <div>
            <RoundedButton
                style={{ marginLeft: '10px' }}
                iconcenter={<ContentCopyIcon sx={{ fontSize: '20px' }} />}
                onClick={() => copyText(props.url)}
            />
        </div>
    )
}

const ButtonSend = (props: any) => {
    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        setLoading(true)
        props.sendEmailInvite({ aplicativo: props.aplicodigo }).then(() => setLoading(false)).catch((e: any) => toast.error(e.message, {toastId: e.message}))
    }

    return (
        (props.aplicodigo == 3) ? <></> :
            <div>
                <RoundedButton
                    style={{ marginLeft: '10px', ...loading && { opacity: 0.5 } }}
                    iconcenter={<SendIcon sx={{ fontSize: '20px' }} />}
                    onClick={handleClick}
                />
            </div>
    )
}

export const AplicativosUsuario = forwardRef((props: any, ref) => {
    const auth = useAuth();
    const [show, setShow] = useState(false);
    const [usucodigo, setUsucodigo] = useState();
    const [usuemail, setUsuemail] = useState();

    function openModal(_data: any) {
        setUsucodigo(_data.usucodigo);
        setUsuemail(_data.usuemail);
        setShow(true);
    }

    function closeModal() {
        if (props.onRefresh)
            props.onRefresh();

        setShow(false);
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    async function updateStatus(_body: any) {
        _body.usucodigo = usucodigo;

        try {
            await auth.post('/v1/aplicativotouser', _body);
        } catch (error) {
            console.log(error);
        }
    }

    async function sendEmailInvite(_body: { aplicativo: number }) {
        console.log(auth.grupo)
        const body = { ..._body, destinatario: usuemail, empresa: auth.grupo.grudescricao }

        try {
            await auth.post('/v1/emailinvite', body);
            const msg = 'Enviado com sucesso'
            toast.info(msg, { toastId: msg });
        } catch (error) {
            console.log(error);
        }
    }

    const columns = [
        { field: 'aplinome', header: 'Nome' },
        {
            field: 'apliusuativo', header: 'Habilitado', body: (row: any) =>
                <ExSwitch
                    defaultChecked={row.apliusuativo === 1}
                    onChange={(e: any) => updateStatus({ aplicodigo: row.aplicodigo, apliusuativo: e.target.checked ? 1 : 0 })}
                />
        },
        { field: 'apliurlandroid', header: 'URL Android', body: (e: any) => <ButtonCopy url={e.apliurlandroid} /> },
        { field: 'apliurlios', header: 'URL IOS', body: (e: any) => <ButtonCopy url={e.apliurlios} /> },
        { field: 'send', header: 'Enviar Convite', body: (e: any) => <ButtonSend aplicodigo={e.aplicodigo} sendEmailInvite={sendEmailInvite} /> },
    ];

    return (
        <Modal
            show={show}
            onHide={closeModal}
            size="xl"
            centered={true}
        >
            <ConsultaPadrao
                model={model_aplicativo_usuario}
                columns={columns}
                sortField="aplicodigo"
                apifilter={"?usucodigo=" + usucodigo}
            />
        </Modal>
    )
});